'use client';

import * as React from 'react';
import { styled } from '@linaria/react';

import { sampleOne } from '@/utils';

import affirmations from './affirmations';

function Affirmation(props: React.HTMLAttributes<HTMLDivElement>) {
  const [affirmation, setAffirmation] = React.useState<string>('');

  React.useEffect(() => {
    setAffirmation(sampleOne(affirmations));
  }, []);

  return <Wrapper {...props}>{affirmation}</Wrapper>;
}

const Wrapper = styled.div`
  /* Prevent layout shift */
  min-height: 1.3125rem;
  font-weight: var(--font-weight-medium);
`;

export default Affirmation;
