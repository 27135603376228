/*
  So the thing is, it's just not realistic to have a single <NewsletterSignup /> component. There are too many possible variations. Instead, this helper module has all of the bits of logic I can use to quickly build as many signup forms as I need.
*/

import * as React from 'react';

import { generateEmail } from '@/helpers/fake-data.helpers';
import useSound from '@/hooks/use-sound';
import type {
  NewsletterFormName,
  NewsletterTagName,
} from '@/constants';
import type { SubscribeErrorType } from '@/server/helpers/subscription.helpers';

import VisuallyHidden from '@/components/VisuallyHidden';

export type Status = 'loading' | 'idle' | 'submitting' | 'success';
export type ConfettiStatus = 'inert' | 'preload' | 'active';

interface Options {
  formName?: NewsletterFormName;
  tags?: Array<NewsletterTagName>;
  callbacks?: {
    onStart?: (ev: React.FormEvent<HTMLFormElement>) => void;
    onSuccess?: (email: string) => void;
    onFailure?: (error: SubscribeErrorType) => void;
  };
}

export function useNewsletterSignup({
  formName = 'primaryNewsletter',
  tags = ['primaryNewsletter'],
  callbacks = {},
}: Options) {
  const id = React.useId();
  const honeypotId = 'pot-' + id.replace(/:/g, '-');
  const emailId = 'email-' + id.replace(/:/g, '-');

  const inputRef = React.useRef<HTMLInputElement>(null);

  const timeoutIdRef = React.useRef<number | null>(null);

  const tagsRef = React.useRef(tags);
  tagsRef.current = tags;
  const callbacksRef = React.useRef(callbacks);
  callbacksRef.current = callbacks;

  const [status, setStatus] = React.useState<Status>('loading');
  const [error, setError] = React.useState<SubscribeErrorType | null>(
    null
  );
  const [numOfEncounteredErrors, setNumOfEncounteredErrors] =
    React.useState(0);

  const [email, setEmail] = React.useState('');
  const [emailPlaceholder, setEmailPlaceholder] = React.useState('');

  const [honeypotTripped, setHoneypotTripped] = React.useState(false);

  React.useEffect(() => {
    setEmailPlaceholder(generateEmail());
    setStatus('idle');

    return () => {
      if (typeof timeoutIdRef.current === 'number') {
        window.clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  const internalHandleFailure = React.useCallback(
    (error: SubscribeErrorType, requestStartAt: number) => {
      // We want to wait at least 500ms before showing the error, to make it not seem automatic / illegitimate.
      const timeElapsed = Date.now() - requestStartAt;
      const timeRemaining = 500 - timeElapsed;

      if (timeRemaining > 0) {
        timeoutIdRef.current = window.setTimeout(() => {
          internalHandleFailure(error, requestStartAt);
        }, timeRemaining);
        return;
      }

      setStatus('idle');
      setError(error);
      setNumOfEncounteredErrors((prev) => prev + 1);

      // We want to re-focus the input so that the user can easily fix their email / try again. The FadeOnChange component takes a bit of time to show the new error message, though. So we’ll synchronize the refocusing with the error message:
      timeoutIdRef.current = window.setTimeout(() => {
        inputRef.current?.focus({ preventScroll: true });
      }, 300);

      callbacksRef.current?.onFailure?.(error);
    },
    [inputRef]
  );

  const handleSubmit = React.useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (status === 'loading') {
        window.alert(
          'This form is still loading. Please try again in a few seconds.'
        );
        return;
      } else if (status === 'submitting') {
        // Ignore requests made by spamming the button
        return;
      }

      const { onStart, onSuccess } = callbacksRef.current;
      const tags = tagsRef.current;

      setStatus('submitting');
      setError(null);

      onStart?.(ev);

      const requestStartAt = Date.now();

      fetch('/api/subscribe-user', {
        method: 'POST',
        body: JSON.stringify({
          email,
          terms: honeypotTripped,
          tags,
          formName,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          // Skip the confetti and all that if we have a URL to forward to:
          if (json.status === 'success' && json.forwardTo) {
            window.location.href = json.forwardTo;
            return;
          }

          if (json.status !== 'success') {
            internalHandleFailure(
              json.status || 'unknown-error',
              requestStartAt
            );
            return;
          }

          setStatus('success');

          onSuccess?.(email);
        })
        .catch(() => {
          internalHandleFailure('unreachable-server', requestStartAt);
        });
    },
    [email, status, formName, honeypotTripped, internalHandleFailure]
  );

  const honeypotElem = (
    <VisuallyHidden>
      <label htmlFor={honeypotId}>
        Are you a human? If so, please ignore this checkbox
      </label>
      <input
        name="accept-terms"
        type="checkbox"
        id={honeypotId}
        checked={honeypotTripped}
        onChange={(event) => {
          setHoneypotTripped(event.target.checked);
        }}
      />
    </VisuallyHidden>
  );

  const emailFields = {
    ref: inputRef,
    type: 'email',
    placeholder: emailPlaceholder,
    value: email,
    onChange: (ev: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(ev.currentTarget.value);
    },
    disabled: status === 'submitting',
    autoComplete: 'email',
    required: true,
  };

  return {
    emailId,
    status,
    error,
    numOfEncounteredErrors,
    emailFields,
    honeypotElem,
    handleSubmit,

    escapeHatches: {
      setEmail,
      inputRef,
    },
  };
}

export function useConfetti() {
  const hasBeenTriggered = React.useRef(false);

  const [confettiStatus, setConfettiStatus] =
    React.useState<ConfettiStatus>('inert');

  const [playFanfare] = useSound('/sounds/fanfare.mp3', {
    volume: 0.4,
  });
  const [playPfff] = useSound('/sounds/pfff.mp3', { volume: 0.4 });

  const timeoutId = React.useRef<number | null>(null);

  const handleLoadConfetti = React.useCallback(() => {
    setConfettiStatus('preload');
  }, []);

  // NOTE: Cannot wrap in `useCallback` since that breaks audio playback. I guess Chrome no longer views the function call as happening in response to an event?
  const handleTriggerConfetti = () => {
    // The confetti only fires the first time, since we aren't resetting `SubscribeGeyser`. Rather than fix that, seems easier to skip the SFX and all the business on repeated submissions.
    if (hasBeenTriggered.current) {
      return;
    }

    hasBeenTriggered.current = true;

    if (typeof timeoutId.current === 'number') {
      window.clearTimeout(timeoutId.current);
    }

    playFanfare();

    timeoutId.current = window.setTimeout(() => {
      playPfff();
      setConfettiStatus('active');
    }, 1750);

    return () => {
      if (typeof timeoutId.current === 'number') {
        window.clearTimeout(timeoutId.current);
      }
    };
  };

  return {
    confettiStatus,
    handleLoadConfetti,
    handleTriggerConfetti,
  };
}
