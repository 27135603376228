/*
  This component is intended to be used, alongside with newsletter.helpers.tsx, whenever we have a newsletter signup form.
  It returns unstyled DOM nodes; for styling, compose with styled-components. For example:

  const ErrorMessage = styled(SubscribeError)`
    p {
      color: red;
    }
  `
*/

import * as React from 'react';

import type { Status } from '@/helpers/newsletter.helpers';
import type { SubscribeErrorType } from '@/server/helpers/subscription.helpers';

import FadeOnChange from '@/components/FadeOnChange';
import TextLink from '@/components/Link/TextLink';
import Spacer from '@/components/Spacer';

type Variant = 'standard' | 'footer' | 'effective-portfolio';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant?: Variant;
  status: Status;
  error: SubscribeErrorType | null;
  // Sometimes, the error appearing and disappearing can cause ugly layout shifts. We can provide an `errorMinHeight`, which will ensure that a spacer is included specifically while there is at least 1 error and the status is `submitting`.
  errorMinHeight?: string;
  numOfEncounteredErrors: number;
  fallback?: React.ReactNode;
}

function SubscribeError({
  variant = 'standard',
  status,
  error,
  errorMinHeight,
  numOfEncounteredErrors,
  fallback,
  ...delegated
}: Props) {
  let errorText = null;

  if (numOfEncounteredErrors > 1) {
    switch (variant) {
      case 'effective-portfolio': {
        errorText = (
          <p>
            Hmm, that still didn’t work. Sorry for the trouble!{' '}
            <strong>
              Please{' '}
              <TextLink href="mailto:support@joshwcomeau.com">
                email me
              </TextLink>{' '}
              and I’ll send you a copy of my book.
            </strong>
          </p>
        );
        break;
      }

      case 'footer': {
        errorText = (
          <>
            <p>Hmm, that still didn’t work. Sorry about that!</p>
            <p>
              Please{' '}
              <TextLink href="mailto:me@joshwcomeau.com">
                shoot me an email
              </TextLink>{' '}
              and I'll make sure you get subscribed.
            </p>
          </>
        );
        break;
      }

      default: {
        errorText = (
          <p>
            Hmm, that still didn’t work. Sorry for the trouble!{' '}
            <strong>
              Please{' '}
              <TextLink href="mailto:support@joshwcomeau.com">
                email me
              </TextLink>{' '}
              and I’ll make sure you get subscribed.
            </strong>
          </p>
        );
        break;
      }
    }
  } else if (error === 'unreachable-server') {
    errorText = (
      <p>
        <strong>The server could not be reached.</strong> Please check
        your internet connection and try again.
      </p>
    );
  } else if (error === 'invalid-email') {
    errorText = (
      <p>
        <strong>Your email address appears to be invalid.</strong> If
        this is a mistake in my validation logic, please{' '}
        <TextLink href="mailto:support@joshwcomeau.com">
          shoot me an email
        </TextLink>{' '}
        and let me know!
      </p>
    );
  } else if (error !== null) {
    // Catch-all, for unpredicted errors / errors that don't need custom messaging.
    errorText = (
      <p>
        <strong>Ack, something went wrong!</strong> Please try again.
      </p>
    );
  }

  // We have “fallback” context which should fade to/from an error message, but the logic for this is surprisingly thorny:
  // • For the happy path, the fallback message should never fade out. It should act like static content.
  // • When an error is encountered, it should fade to the error, but it should NOT fade back to the disclaimer on submit. It should stay blank instead, until the next error or a successful submission.
  //
  // NOTE: There is a known bug right now where the `fallback` doesn't come back on successful submission after encountering at least 1 error. I don't think it's a big enough deal to worry about.

  const isRetrying =
    numOfEncounteredErrors > 0 && status === 'submitting';

  return (
    <FadeOnChange changeKey={`${error}-${numOfEncounteredErrors}`}>
      {!!error && <div {...delegated}>{errorText}</div>}
      {!error && !isRetrying && fallback}
      {!error && isRetrying && !!errorMinHeight && (
        <Spacer size={errorMinHeight || '0'} />
      )}
    </FadeOnChange>
  );
}

export default SubscribeError;
