export default [
  '🏳️‍⚧️ Trans rights are human rights.',
  'I hope you’re having a wonderful day!',
  'I hope you’re having a stellar day!',
  'I hope you’re having a marvelous day!',
  'I hope you’re having a sensational day!',
  'Be kind, rewind.',
  'An apple a day keeps the doctor away. 🍎',
  'Every day is an adventure. ✨',
  'You belong in this world. 💖',
  'You are an unstoppable force of nature. 🌪️',
  'Be as kind to yourself as you are to others.',
  'You miss 100% of the shots you don’t take. 🏀',
  'You are a rockstar. 🌟',
  'Thanks for reading. 💖',
  'Hey! What a wonderful kind of day.',
  'Move it, football head!',
];
