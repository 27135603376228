'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import useBoopMinimal from '@/hooks/use-boop-minimal';
import useRequestAnimationFrameLoop from '@/hooks/use-request-animation-frame-loop';
import {
  useNewsletterSignup,
  Status,
} from '@/helpers/newsletter.helpers';

import Spinner from '@/components/Spinner';
import VisuallyHidden from '@/components/VisuallyHidden';
import FallAway from '@/components/FallAway';
import SubscribeError from '@/components/SubscribeError';

import FooterSuccessClouds from './FooterSuccessClouds';

function FooterNewsletterForm({
  ...delegated
}: React.HTMLAttributes<HTMLFormElement>) {
  const {
    status,
    emailFields,
    error,
    numOfEncounteredErrors,
    emailId,
    honeypotElem,
    handleSubmit,
    escapeHatches: { inputRef },
  } = useNewsletterSignup({
    tags: ['primaryNewsletter'],
    formName: 'primaryNewsletter',
  });

  return (
    <OuterWrapper>
      <Wrapper onSubmit={handleSubmit} {...delegated}>
        {honeypotElem}
        <Intro
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          <FallAway
            when={status === 'success'}
            distance={7.5}
            rotation={10}
            delay={300}
          >
            Want to know when I publish new content?
          </FallAway>
          <FallAway
            when={status === 'success'}
            distance={7}
            rotation={-5}
            delay={200}
          >
            Enter your email to join my free newsletter:
          </FallAway>
        </Intro>
        <VisuallyHidden>
          <label htmlFor={emailId}>Email</label>
        </VisuallyHidden>
        <FallAway
          when={status === 'success'}
          distance={4}
          rotation={3}
          delay={0}
        >
          <Row>
            <Input
              id={emailId}
              {...emailFields}
              aria-hidden={status === 'success'}
              name="email"
            />
            <SubmitButton status={status} />
          </Row>
        </FallAway>

        <Error
          variant="footer"
          status={status}
          error={error}
          // HACK: To avoid layout shifts, I specify a minimum height for the error, but this changes depending on which error is shown. The repeat error message is a bit longer.
          errorMinHeight={
            numOfEncounteredErrors < 2 ? '5.6875rem' : '6.5625rem'
          }
          numOfEncounteredErrors={numOfEncounteredErrors}
        />
      </Wrapper>
      {status === 'success' && (
        <FooterSuccessClouds baseDelay={600} />
      )}
    </OuterWrapper>
  );
}

function SubmitButton({ status }: { status: Status }) {
  const [progress, setProgress] = React.useState(0);

  const [isHovering, setIsHovering] = React.useState(false);
  const isBooped = useBoopMinimal(isHovering, 150);

  const boopStrength = 3;
  const lineSpring = useSpring({
    x2: isBooped ? 18 + boopStrength : 18,
    config: {
      tension: 300,
      friction: 10,
    },
  });
  const caretSpring = useSpring({
    points: isBooped
      ? `${12 + boopStrength} 5 ${19 + boopStrength} 12 ${12 + boopStrength} 19`
      : '12 5 19 12 12 19',
    config: {
      tension: 300,
      friction: 10,
    },
  });

  React.useEffect(() => {
    switch (status) {
      case 'submitting': {
        setProgress(0);
        return;
      }
      case 'success': {
        setProgress(100);
        return;
      }
    }
  }, [status]);

  useRequestAnimationFrameLoop(() => {
    const progressIncrement = (100 - progress) * 0.0175;
    setProgress((p) => Math.min(100, p + progressIncrement));
  }, status === 'submitting');

  const iconSize = 20;

  const showArrow = status !== 'submitting' && status !== 'success';

  return (
    <Button
      type="submit"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      disabled={status === 'submitting' || status === 'success'}
    >
      <ProgressIndicator
        style={{
          '--progress': progress + '%',
          opacity: status === 'submitting' ? 1 : 0,
          transition:
            status === 'submitting'
              ? 'opacity 50ms'
              : 'opacity 1000ms',
        }}
      />
      <VisuallyHidden>Submit</VisuallyHidden>
      <ArrowIcon
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          transform: showArrow
            ? 'translateX(0rem)'
            : 'translateX(1.75rem)',
          transitionTimingFunction:
            status === 'submitting'
              ? 'cubic-bezier(0.36, -0.01, 0.91, 0.63)'
              : 'cubic-bezier(0.23, 0.62, 0.41, 1.01)',
        }}
      >
        <animated.line x1="5" y1="12" y2="12" {...lineSpring} />
        <animated.polyline {...caretSpring} />
      </ArrowIcon>
      {status === 'submitting' && (
        <SpinnerWrapper>
          <Spinner size={20} speed={7} />
        </SpinnerWrapper>
      )}
    </Button>
  );
}

const OuterWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.form`
  position: relative;
  padding-bottom: 16px;
  /*
    I measured, and this is the default height of the form.
    Doing this to prevent a layout shift when the success message is swapped in.
  */
  min-height: 7.25rem;
  /*
    On success, the fields fall away, and it looks a bit cleaner if we cut them off. Still looks fine in browsers that don't support 'overflow: clip'
  */
  overflow-y: clip;
`;

const Intro = styled.p`
  font-size: 0.875rem;
  margin-bottom: 1em;
  cursor: default;
  max-width: 25rem;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  background: var(--color-background);
  max-width: 22rem;
  border-radius: 8px;
  padding: 4px;
  margin-left: -12px;
  margin-right: -12px;

  &:has(input:focus-visible) {
    background: var(--color-background);
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 3px;
    outline-offset: 2px;
  }
`;

const Input = styled.input`
  flex: 1;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 4px 8px;
`;
const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  background: black;
  color: white;
  border-radius: 4px;
  font-size: 0.875rem;
  transition:
    color var(--color-swap-duration),
    background var(--color-swap-duration);
  /* Hide the arrow when it takes off */
  overflow: hidden;
  overflow: clip;

  html[data-color-mode='dark'] & {
    background: hsl(50deg 100% 50%);
    background: oklch(0.9 0.22 97.52);
    color: black;
  }
`;

const ProgressIndicator = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 120%;
  height: 100%;
  clip-path: polygon(
    0% 0%,
    var(--progress) 0%,
    calc(var(--progress) + 20%) 100%,
    0% 100%
  );

  html[data-color-mode='light'] & {
    background: hsl(200deg 70% 70% / 0.4);
  }
  html[data-color-mode='dark'] & {
    background: hsl(0deg 0% 100% / 0.8);
  }
`;

const ArrowIcon = styled.svg`
  position: absolute;
  inset: 0;
  margin: auto;
  transition: transform 300ms;
`;

const SpinnerWrapper = styled.span`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: enter 500ms both;
  animation-delay: 200ms;

  @keyframes enter {
    from {
      opacity: 0;
      transform: scale(0.25);
    }
  }
`;

const Error = styled(SubscribeError)`
  --color-primary: var(--color-error-500);
  background: var(--color-error-100);
  max-width: 22rem;
  border-radius: 8px;
  padding: 12px;
  margin-left: -12px;
  margin-top: 4px;
  animation: dropIn 400ms cubic-bezier(0.23, 0.62, 0.41, 1.01) both;
  animation-delay: 200ms;

  p {
    margin-bottom: 1em;
    font-size: 0.875rem;
    color: var(--color-error-500);
    text-align: center;
    animation: fadeIn 300ms 400ms both;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  @keyframes dropIn {
    from {
      opacity: 0;
      transform: translateY(-6px);
    }
  }
`;

export default FooterNewsletterForm;
