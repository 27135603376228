import * as React from 'react';
import { useSpring, SpringValue } from 'react-spring';

import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';

type BoopStyle = {
  transform: SpringValue<string>;
  backfaceVisibility: 'hidden';
};
type BoopTrigger = () => void;

function useBoop({
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1,
  timing = 150,
  springConfig = {
    tension: 300,
    friction: 10,
  },
}): [BoopStyle, BoopTrigger] {
  const prefersReducedMotion = usePrefersReducedMotion();

  const [isBooped, setIsBooped] = React.useState(false);

  const style: BoopStyle = {
    ...useSpring({
      transform:
        isBooped && !prefersReducedMotion
          ? `translate(${x}px, ${y}px)
         rotate(${rotation}deg)
         scale(${scale})`
          : `translate(0px, 0px)
         rotate(0deg)
         scale(1)`,
      config: springConfig,
    }),
    backfaceVisibility: 'hidden',
  };

  React.useEffect(() => {
    if (!isBooped) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);

  const trigger = React.useCallback(() => {
    setIsBooped(true);
  }, []);

  return [style, trigger];
}

export default useBoop;
