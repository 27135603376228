import * as React from 'react';
import { styled } from '@linaria/react';

interface Props {
  when: boolean;
  as?: any;
  distance: number; // in rems
  duration?: number;
  rotation?: number;
  delay?: number;
  children: React.ReactNode;
}

function FallAway({
  when,
  as = 'span',
  distance,
  duration = 500,
  rotation = 0,
  delay = 0,
  children,
}: Props) {
  return (
    <Wrapper
      as={as}
      data-animate={when}
      style={{
        '--duration': duration + 'ms',
        '--distance': distance + 'rem',
        '--rotation': rotation + 'deg',
        '--delay': delay + 'ms',
      }}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.span`
  display: block;

  &[data-animate='true'] {
    animation: fallAway var(--duration) cubic-bezier(0.28, 0, 1, 0.65)
      forwards;
    animation-delay: var(--delay);
  }

  @keyframes fallAway {
    from {
      transform: translateY(0) rotate(0);
      opacity: 1;
    }

    to {
      transform: translateY(var(--distance)) rotate(var(--rotation));
      opacity: 0;
    }
  }
`;

export default FallAway;
