'use client';

import * as React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import { SPRINGS } from '@/constants';
import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';
import useSound from '@/hooks/use-sound';

import {
  UserPreferencesContext,
  SetUserPreferencesContext,
} from '@/components/UserPreferencesProvider';

import { IconProps } from './Icon.types';
import IconWrapper from './IconWrapper';

interface Props extends IconProps {
  soundEnabled: boolean;
  isBooped: boolean;
  hasBeenToggled: boolean;
}

export function IconSpeaker({
  soundEnabled,
  isBooped,
  hasBeenToggled,
  size = 20,
  ...delegated
}: Props) {
  const prefersReducedMotion = usePrefersReducedMotion();

  const speakerSpring = useSpring({
    transform: `rotate(${isBooped && !soundEnabled ? -10 : 0}deg)`,
    immediate: prefersReducedMotion,
    config: SPRINGS.springy,
  });

  const waveOneSpring = useSpring({
    transform: `translateX(${isBooped && soundEnabled ? 1.5 : 0}px)`,
    immediate: prefersReducedMotion,
    config: SPRINGS.springy,
  });
  const waveTwoSpring = useSpring({
    transform: `translateX(${isBooped && soundEnabled ? 2 : 0}px)`,
    immediate: prefersReducedMotion,
    config: SPRINGS.springy,
    delay: 50,
  });

  return (
    <Svg
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 24 24"
      fill="none"
      {...delegated}
    >
      <SpeakerWrapper
        style={{
          '--x': soundEnabled ? '0px' : '5px',
          transitionDelay: soundEnabled ? '0ms' : '150ms',
        }}
      >
        <Speaker
          d="M12.0133 4.60757C11.9149 4.2957 11.5463 4.19331 11.2861 4.39151L5.93425 8.46914C5.84716 8.5355 5.74071 8.57143 5.63123 8.57143H1.5C1.22386 8.57143 1 8.79529 1 9.07143V14.9286C1 15.2047 1.22386 15.4286 1.5 15.4286H5.63123C5.74071 15.4286 5.84716 15.4645 5.93425 15.5309L11.2861 19.6085C11.5463 19.8067 11.9149 19.7043 12.0133 19.3924C12.3582 18.2999 13 15.7612 13 12C13 8.2388 12.3582 5.70014 12.0133 4.60757Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          data-should-wiggle={String(soundEnabled && hasBeenToggled)}
          style={speakerSpring}
        />
      </SpeakerWrapper>

      <WaveWrapper
        style={{
          opacity: soundEnabled ? 1 : 0,
          transitionDelay: soundEnabled ? '0ms' : '150ms',
        }}
      >
        <Path
          d={`
              M 17.54 8.46002
              C 18.4774 9.39766 19.004 10.6692 19.004 11.995
              C 19.004 13.3208 18.4774 14.5924 17.54 15.53
          `}
          strokeLinecap="round"
          strokeLinejoin="round"
          style={waveOneSpring}
        />
      </WaveWrapper>
      <WaveWrapper
        style={{
          opacity: soundEnabled ? 1 : 0,
          transitionDelay: soundEnabled ? '150ms' : '0ms',
        }}
      >
        <Path
          d={`
              M 20.0703 4.92999
              C 21.945 6.80527 22.9982 9.34835 22.9982 12
              C 22.9982 14.6516 21.945 17.1947 20.0703 19.07
          `}
          strokeLinecap="round"
          strokeLinejoin="round"
          style={waveTwoSpring}
        />
      </WaveWrapper>
    </Svg>
  );
}

interface WrappedProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  size?: number;
}

function WrappedIconSpeaker({
  size = 20,
  ...delegated
}: WrappedProps) {
  const { soundEnabled } = React.useContext(UserPreferencesContext);
  const { toggleSoundEnabled } = React.useContext(
    SetUserPreferencesContext
  );

  // Don't do the wiggly thing on mount. Only when sound is toggled.
  const [hasBeenToggled, setHasBeenToggled] = React.useState(false);

  const [playOn] = useSound('/sounds/enable-sound.mp3', {
    volume: 0.4,
  });
  const [playOff] = useSound('/sounds/disable-sound.mp3', {
    volume: 0.5,
  });

  return (
    <IconWrapper
      alt={soundEnabled ? 'Disable sounds' : 'Enable sounds'}
      {...delegated}
      onClick={(ev) => {
        ev.preventDefault();

        setHasBeenToggled(true);

        toggleSoundEnabled(!soundEnabled);

        if (soundEnabled) {
          playOff({ forceSoundEnabled: true });
        } else {
          playOn({ forceSoundEnabled: true });
        }
      }}
    >
      {({ isBooped }) => (
        <IconSpeaker
          size={size}
          soundEnabled={soundEnabled}
          isBooped={isBooped}
          hasBeenToggled={hasBeenToggled}
        />
      )}
    </IconWrapper>
  );
}

const Svg = styled.svg`
  display: block;
  overflow: visible;
`;

const Path = styled(animated.path)`
  stroke: currentColor;
  stroke-width: 2px;
`;

const SpeakerWrapper = styled.g`
  transform: translateX(var(--x));

  @media (prefers-reduced-motion: no-preference) {
    transition: transform 300ms;
  }
`;
const Speaker = styled(Path)`
  transform-origin: 30% center;

  @media (prefers-reduced-motion: no-preference) {
    &[data-should-wiggle='true'] {
      animation: speakerWiggle 350ms;
    }
  }

  @keyframes speakerWiggle {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(-13deg);
    }
    75% {
      transform: rotate(12deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const WaveWrapper = styled.g`
  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 200ms;
  }
`;

export default WrappedIconSpeaker;
