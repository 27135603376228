import { styled } from '@linaria/react';

const Svg = styled.svg`
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  will-change: transform;
  backface-visibility: hidden;
  transition: opacity 200ms;
`;

export default Svg;
