'use client';

import { styled } from '@linaria/react';
import { animated } from 'react-spring';
import { GitHub, Linkedin } from 'react-feather';

import useBoop from '@/hooks/use-boop';

import VisuallyHidden from '@/components/VisuallyHidden';

const ICON_MAP = {
  github: GitHub,
  linkedin: Linkedin,
};

interface Props {
  href: string;
  alt: string;
  icon: keyof typeof ICON_MAP;
}

function IconLink({ href, alt, icon }: Props) {
  const [style, trigger] = useBoop({
    rotation: 5,
  });

  const Icon = ICON_MAP[icon];

  return (
    <Wrapper href={href} style={style} onMouseEnter={trigger}>
      <Icon size={20} />
      <VisuallyHidden>{alt}</VisuallyHidden>
    </Wrapper>
  );
}

const Wrapper = styled(animated.a)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  color: inherit;
  transform-origin: 50% 85%;
  /* For focus outlines: */
  border-radius: 1000px;
  outline-offset: 2px;

  /* Increase tap target size */
  &::before {
    content: '';
    position: absolute;
    inset: -4px;
  }

  &:hover {
    color: var(--color-text);
  }
`;

export default IconLink;
