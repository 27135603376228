'use client';

import React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import { SEARCH_MODAL_ID } from '@/constants';
import { useGlobalUIActions } from '@/components/GlobalUIProvider';

import { IconProps } from './Icon.types';
import IconWrapper from './IconWrapper';

export function IconSearch({
  size = 20,
  isBooped,
  style = {},
  ...delegated
}: IconProps) {
  const iconStyle = useSpring({
    ...style,
    transform: isBooped
      ? 'scale(1.1) rotate(8deg)'
      : 'scale(1) rotate(0deg)',
    config: {
      tension: 300,
      friction: 10,
    },
  });
  const shimmerStyle = useSpring({
    transform: isBooped
      ? 'rotate(-20deg) translateX(-40%)'
      : 'rotate(-20deg) translateX(50%)',
    config: {
      tension: 300,
      friction: 12,
    },
  });

  return (
    <Wrapper>
      <ShimmerWrapper>
        <Shimmer style={shimmerStyle} />
      </ShimmerWrapper>
      <Svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width={size / 16 + 'rem'}
        height={size / 16 + 'rem'}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...delegated}
        style={iconStyle}
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </Svg>
    </Wrapper>
  );
}

function WrappedIconSearch(props: Omit<IconProps, 'isBooped'>) {
  const { toggleModal } = useGlobalUIActions();

  return (
    <>
      <IconWrapper
        alt="Search"
        onClick={() => {
          toggleModal(SEARCH_MODAL_ID);
        }}
      >
        {({ isBooped }) => (
          <IconSearch {...props} isBooped={isBooped} />
        )}
      </IconWrapper>
    </>
  );
}

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
  transition:
    transform 300ms cubic-bezier(0.06, 0.63, 0.43, 1),
    opacity 300ms;
`;

const ShimmerWrapper = styled.span`
  position: absolute;
  width: 50%;
  height: 45%;
  top: 23%;
  right: 30%;
  border-radius: 50%;
  overflow: hidden;
  overflow: clip;
  transform: scale(0.85) rotate(-20deg);
  transform-origin: center center;
  transition: opacity 500ms;
  transition-delay: 400ms;

  html[data-color-mode='light'] & {
    opacity: 0;
    transition: opacity 0ms;
    transition-delay: 0ms;
  }
`;

const Shimmer = styled(animated.span)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--search-shimmer-color, white);
  opacity: var(--search-shimmer-opacity, 0.5);
  animation: shimmer 400ms both;
  border-radius: 50%;

  html[data-color-mode='light'] & {
    background: var(--search-shimmer-color, var(--color-background));
    opacity: var(--search-shimmer-opacity, 1);
  }
`;

const Svg = styled(animated.svg)`
  stroke: currentColor;
  display: block;
  overflow: visible;
  transform-origin: ${(11 / 24) * 100}% ${(11 / 24) * 100}%;
`;

export default WrappedIconSearch;
