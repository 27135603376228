import { sampleOne } from '@/utils';

export const generateFirstName = () => {
  return sampleOne([
    'Maria',
    'Nushi',
    'Mohammed',
    'Jose',
    'Wei',
    'Ahmed',
    'Yan',
    'Ali',
    'John',
    'David',
    'Abdul',
    'Anna',
    'Michael',
    'Juan',
    'Mary',
    'Jean',
    'Robert',
    'Daniel',
    'Luis',
    'Carlos',
    'James',
    'Antonio',
    'Joseph',
    'Elena',
    'Francisco',
    'Hong',
    'Marie',
    'Peter',
    'Fatima',
    'Richard',
    'Paul',
    'Olga',
    'Rosa',
    'William',
    'Elizabeth',
    'Patricia',
    'Mario',
    'Luigi',
    'Patrick',
    'Svetlana',
    'Natalya',
    'Sarah',
    'Samuel',
  ]);
};

export const generateLastName = () => {
  return sampleOne([
    'Wang',
    'Smith',
    'Johnson',
    'Williams',
    'Devi',
    'Ivanov',
    'Kim',
    'Ali',
    'García',
    'Müller',
    'da Silva',
    'Singh',
    'Wilson',
    'Martinez',
    'Anderson',
    'Taylor',
    'Tesfaye',
    'Nguyen',
    'González',
    'Deng',
    'Lopez',
    'Rodriguez',
    'Lee',
    'Moore',
    'Hernandez',
    'Jackson',
    'Hansen',
    'Chen',
    'Schmidt',
    'Gomez',
    'Reyes',
    'Zhao',
    'Hoffmann',
    'Kumar',
    'Tremblay',
    'Li',
    'Wu',
    'Ahmed',
    'Jiang',
  ]);
};

export const generateFullName = () => {
  let firstName = generateFirstName();
  let lastName = generateLastName();
  let prefix, initial;

  // 25% chance of middle initial
  if (Math.random() <= 0.25) {
    // prettier-ignore
    initial = sampleOne(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']) + '.';
  }

  // 5% of Dr. prefix
  if (Math.random() <= 0.05) {
    prefix = 'Dr.';
  }

  return [prefix, firstName, initial, lastName]
    .filter(Boolean)
    .join(' ');
};

export const generateEmailDomain = () => {
  return sampleOne([
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'yahoo.co.uk',
    'live.com',
    'web.de',
    'libero.it',
    'comcast.net',
  ]);
};

export const generateEmail = () => {
  const firstName = generateFirstName().toLowerCase();
  const domain = sampleOne([
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'yahoo.co.uk',
    'live.com',
    'web.de',
    'libero.it',
    'comcast.net',
    generateLastName().toLowerCase() + '.dev',
  ]);

  return `${firstName}@${domain}`;
};
