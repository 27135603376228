import React from 'react';
import { styled } from '@linaria/react';
import { animated, useSpring } from 'react-spring';

import { SPRINGS } from '@/constants';
import usePrefersReducedMotion from '@/hooks/use-prefers-reduced-motion';
import useBoopMinimal from '@/hooks/use-boop-minimal';

import Svg from './Svg';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: string | number;
  isHovering: boolean;
  accentColor: string;
  timing?: number;
  style?: React.CSSProperties;
}

const ExternalIcon = ({
  size = 16,
  isHovering,
  accentColor,
  timing = 150,
  style = {},
  ...delegated
}: Props) => {
  const id = React.useId();

  const isBooped = useBoopMinimal(isHovering, timing);

  const maskId = `external-icon-mask-${id}`;

  const prefersReducedMotion = usePrefersReducedMotion();

  const springSettings = {
    config: SPRINGS.springy,
    immediate: prefersReducedMotion,
  };

  const arrowSpring = useSpring({
    d: isBooped
      ? `M 10 14 L 21 3 h -6 h 6 v 6`
      : `M 10 14 L 20 4 h -6 h 6 v 6`,
    ...springSettings,
  });

  return (
    <Svg
      viewBox="0 0 24 24"
      fill="none"
      style={{
        width: size,
        height: size,
        opacity: isHovering ? 1 : 0.7,
        ...style,
      }}
      {...delegated}
    >
      <mask id={maskId}>
        <rect x="0" y="0" width="24" height="24" fill="white" />
        <rect x="10" y="0" width="16" height="14" fill="black" />
      </mask>
      <animated.rect
        x={3}
        y={6}
        width={15}
        height={15}
        rx={2}
        mask={`url(#${maskId})`}
      />
      <Arrow
        {...arrowSpring}
        stroke={isHovering ? accentColor : 'currentColor'}
      />
    </Svg>
  );
};

const Arrow = styled(animated.path)`
  transition: stroke 250ms;
`;

export default ExternalIcon;
